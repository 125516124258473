<script setup lang="ts">
import { Vue3Marquee } from 'vue3-marquee';
import { useDesignOptions } from '../composables/useDesignOptions.ts';
import type { DesignOptions } from '../types/content';

const props = withDefaults(
  defineProps<{
    direction?: 'normal' | 'reverse';
    duration?: number;
    items?: { src: string; alt?: string; title?: string }[];
    concurrentItems?: number;
    designOptions: DesignOptions;
  }>(),
  {
    direction: 'normal',
    duration: 20,
    items: [],
    concurrentItems: 3,
  }
);

const { inlineStyles } = useDesignOptions(props.designOptions);
</script>

<template>
  <section class="u-marquee" :style="{ ...inlineStyles, '--items': concurrentItems }">
    <div>
      <Vue3Marquee :direction="direction" :duration="duration">
        <slot>
          <img
            v-for="item in items"
            :key="JSON.stringify(item)"
            class="u-marquee__item"
            v-bind="item"
            :loading="'lazy'"
          />
        </slot>
      </Vue3Marquee>
    </div>
  </section>
</template>

<style scoped lang="scss">
@layer components {
  .u-marquee {
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    background: var(--background-color);
    color: var(--font-color);

    &__item {
      width: calc(100vw / 2);
      height: 80px;
      object-fit: contain;
      padding-inline: 50px;
    }

    @include mq-above($screen-medium) {
      &__item {
        width: calc(100vw / var(--items));
      }
    }
  }
}
</style>
